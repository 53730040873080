import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="organizer"
export default class extends Controller {
  connect() {
    // app/assets/javascripts/comments.js
    $('#organizer_org_setting_attributes_working_days').select2()
    $(".show-nested-comments").on("click", function(event) {
      event.preventDefault();

      const commentId = $(this).data("commentId");
      const nestedComments = $("#nested-comments-" + commentId);

      if (nestedComments.is(":hidden")) {
        nestedComments.show();
        $(this).text("Hide nested comments");
      } else {
        nestedComments.hide();
        $(this).text("Show nested comments");
      }
    });

   $(document).ready(function() {
      function togglePasswordVisibility(passwordField, toggleButton) {
        const isPasswordVisible = $(passwordField).attr('type') === 'text';
        $(passwordField).attr('type', isPasswordVisible ? 'password' : 'text');
        $(toggleButton).find('i').toggleClass('bi-eye bi-eye-slash');
      }

      $('#user-toggle-password').on('click', function() {
        togglePasswordVisibility('#user-password', this);
      });

      $('#user-toggle-password-confirmation').on('click', function() {
        togglePasswordVisibility('#user-password-confirmation', this);
      });

      $('#toggle-password').on('click', function() {
        togglePasswordVisibility('#login-password', this);
      });
    });

    $('#org_setting_apply_duration, #org_setting_refere_period').on('keydown', function(e) {
      if ( e.key == '-' || e.key == '+' ) {
        e.preventDefault();
      }
    });
    // restrictInputLength('#employee_contact_number', 10);

    $('#org_setting_refere_period, #org_setting_apply_duration').on('keydown', function(event) {
      let inputValue = $(this).val() || '';
      let parts = inputValue.split('.');
      let decimalPart = parts[1] || '';
      let allowedKeys = ['Backspace', 'Delete', '.']

      if ((decimalPart.length >= 2 ) && !allowedKeys.includes(event.key)){
        event.preventDefault();
      }

    });

    validatorBasic()
    customSelect2('#user_gender', true);
    customSelect2('#user_role_id', true);
    customSelect2('#user_country', true);
    customSelect2('#organizer_organization_size', true);
    customSelect2('#org_setting_referal_policy_id', true);
    customSelect2('#country_code', true);
    customSelect2('#organizer_country_code', true);

    $('.SmtpSettingFrom').validate({
       rules: {
        "setting_smtp[username]": {
          emailregex: true
        },
        'setting_smtp[domain]':{
          domainregex: true
        }
      },
      messages: {
        "setting_smtp[username]":{
          emailregex: 'Please enter a valid email address'
        },
        "setting_smtp[password]":{
          minlength: 'Password must be 8-15 characters long',
          maxlength: 'Password must be 8-15 characters long'
        }
      }
    })

    $('#organizer_sign_up').validate({
      rules: {
        "first_name": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 25
        },
        "last_name": {
          required: true,
          nameregex: true,
          minlength: 3,
          maxlength: 25
        },
        "password": {
          required: true,
          passwordregex: true,
          minlength: 8,
          maxlength: 15
        },
        "password_confirmation": {
          required: true,
          passwordmatch: true
        },
        "company_name": {
          required: true,
          nameregex: true,
          minlength: 3,
          maxlength: 50
        },
        "email": {
          required: true,
          emailregex: true
        },
        "contact_number": {
          phoneregex: true,
          minlength: 10,
          maxlength: 10
        },
        "address": {
          minlength: 2,
          maxlength: 150
        },
        "organization_size": {
          required: true
        },
        "country": {
          required: true
        }
      },
      messages: {
        "first_name": {
          required: 'First name is required',
          nameregex: 'First name contains alphabets only',
          minlength: 'First name must be 2-25 characters long',
          maxlength: 'First name must be 2-25 characters long'
        },
        "last_name": {
          required: 'Last name is required',
          nameregex: 'Last name contains alphabets only',
          minlength: 'Last name must be 3-25 characters long',
          maxlength: 'Last name must be 3-25 characters long'
        },
        "password": {
          required: 'Password is required',
          minlength: 'Password must be 8-15 characters long',
          maxlength: 'Password must be 8-15 characters long'
        },
        "password_confirmation": {
          required: 'Password confirmation is required',
          passwordmatch: 'Password and confirmation password do not match'
        },
        "company_name": {
          required: 'Company name is required',
          nameregex: 'Company name contains alphabets only',
        },
        "contact_number": {
          phoneregex: "Please enter valid contact number",
          maxlength: "Contact number must be 10 digits long"
        },
        "email": {
          required: 'Email is required',
          emailregex: 'Please enter a valid email address'
        },
        "address": {
          minlength: 'Address must be 2-150 characters long',
          maxlength: 'Address must be 2-150 characters long'
        },
        "organization_size": {
          required: 'Organization size is required'
        },
        "country": {
          required: 'Country is required'
        }
      },
      errorElement: 'div',
      errorPlacement: function (error, element) {
        error.addClass('invalid-feedback');
        element.closest('.form-floating').append(error);
      },
      highlight: function (element, errorClass, validClass) {
        $(element).addClass('is-invalid');
      },
      unhighlight: function (element, errorClass, validClass) {
        $(element).removeClass('is-invalid');
      }
    })

    $('.user_validation').validate({
      rules: {
        "user[first_name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 25
        },
        "user[last_name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 25
        },
        "user[role_id]": {
          required: true
        },
        "user[email]": {
          required: true,
          emailregex: true
        },
        "user[contact_number]": {
          phoneregex: true,
          minlength: 10,
          maxlength: 10
        },
        "user[password]": {
          // required: true,
          passwordregex: true,
          minlength: 6,
          maxlength: 18
        },
        "user[password_confirmation]": {
          // required: true,
          userpasswordmatch: true
        }
      },
      messages: {
        "user[first_name]": {
          required: 'First name is required.',
          nameregex: 'The first name can only consist of alphabets.',
          minlength: 'Must be 2-25 characters long',
          maxlength: 'Must be 2-25 characters long'
        },
        "user[role_id]": {
          required: 'Role is required.',
          nameregex: 'The last name can only consist of alphabets.',
          minlength: 'Must be 2-25 characters long',
          maxlength: 'Must be 2-25 characters long'
        },
        "user[last_name]": {
          required: 'Last name is required.',
        },
        "user[contact_number]": {
          phoneregex: 'Please enter a valid number',
          maxlength: 'Mobile number must have 10 digit'
        },
        "user[email]": {
          required: 'Email is required.',
          emailregex: 'Please enter a valid email address.'
        },
        "user[password]": {
          required: 'Please enter password.',
          minlength: 'Must be 6-18 characters long',
          maxlength: 'Must be 6-18 characters long'
        },
        "user[password_confirmation]": {
          required: 'Please enter password confirmation.',
          userpasswordmatch: 'Must be match with password.'
        }
      }
    })

    $('.settingFrom').validate({
      rules: {
        "organizer[name]": {
          required: true,
          nameregex: true,
          minlength: 3,
          maxlength: 50
        },
        "organizer[phone]": {
          required: true,
          phoneregex: true,
          minlength: 10,
          maxlength: 10
        },
        "organizer[organization_size]": {
          required: true
        },
        'organizer[registration_number]': {
          maxlength: 25
        }
      },
      messages: {
        "organizer[name]": {
          required: 'Organization name is required.',
          nameregex: 'The company name can only consist of alphabets.',
        },
        "organizer[phone]": {
          required: 'Contact number is required.',
          phoneregex: "Only includes digits with country code."
        },
        "organizer[organization_size]": {
          required: 'Organization size is required.'
        },
        'organizer[registration_number]': {
          maxlength: "Maximum 25 digits are allowed."
        }
      }
    })

    $('#organizer-sign-in').validate({
      rules: {
        "organizer": {
          required: true,
        },
        "email": {
          required: true,
          emailregex: true
        }
      },
      messages: {
        "organizer": {
          required: 'Organizer name is required.',
        },
        "email": {
          required: 'Email is required.',
          emailregex: "Please enter a valid email address."
        }
      }
    })
    $('.referralSettingForm').validate({
      rules: {
        "org_setting[refere_period]": {
          required: true,
          max: 999.99,
          min: 0,
          percentage_validator: true,
        },
        "org_setting[apply_duration]": {
          required: true,
          max: 999.99,
          min: 0,
          percentage_validator: true,
        }
      },
      messages: {
        "org_setting[refere_period]": {
          required: 'This field is required.',
          max: 'Reference period valid from 0-999.99',
          min: 'Reference period valid from 0-999.99'
        },
        "org_setting[apply_duration]": {
          required: 'This field is required.',
          max: 'Apply duration valid from 0-999.99',
          min: 'Apply duration valid from 0-999.99'
        }
      }
    })
    $('#forgot-password-page').validate({
      rules: {
        "email": {
          required: true,
          emailregex: true
        },
        "employee[email]": {
          required: true,
          emailregex: true
        },        
        "user[email]": {
          required: true,
          emailregex: true
        }
      },
      messages: {
        "employee[email]": {
          required: 'This is a required field.',
          emailregex: "Please enter a valid email address."
        },
        "email": {
          required: 'This is a required field.',
          emailregex: "Please enter a valid email address."
        },        
        "user[email]": {
          required: 'This is a required field.',
          emailregex: "Please enter a valid email address."
        }
      },
      errorElement: 'span',
    })

    $('.separationFrom').validate({
      rules: {
        "org_setting[separation_visibility]": {
          required: true,
          min: 0,
          max: 99,
        },
        "org_setting[separation_period]": {
          required: true,
          min: 0,
          max: 999,
        }
      },
      messages: {
        "org_setting[separation_visibility]": {
          required: 'This is a required field.',
        },
        "org_setting[separation_period]": {
          required: 'This is a required field.',
        }
      }
    })
    $('.new_employee').validate({
      rules: {
        "employee[password]": {
          required: true,
          passwordregex: true,
        },
        "employee[password_confirmation]": {
          required: true,
          passwordregex: true,
        }
      },
      messages: {
        "employee[password]": {
          required: 'Please enter password.',
        },
        "employee[password_confirmation]": {
          required: 'Please enter password confirmation.',
        }
      },
      errorElement: 'div',
      errorPlacement: function (error, element) {
        error.addClass('invalid-feedback');
        element.closest('.form-floating').append(error);
      },
    })

    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z]+(?:\s[a-zA-Z]+)*(?:\s?)$/.test(value);
    },'Contains alphabets only');

    $.validator.addMethod('phoneregex', function(value, element){
      return this.optional(element) || /^[0-9]+$/.test(value);
    },'Contains digits only');

    $.validator.addMethod('emailregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z0-9]+(?:[._%+()-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/g.test(value);
    });

    $.validator.addMethod('passwordmatch', function(value, element){
      var password = $("[name='password']").val()
      return this.optional(element) || password == value;
    });

    $.validator.addMethod('userpasswordmatch', function(value, element){
      var password = $("[name='user[password]']").val()
      return this.optional(element) || password == value;
    });

    $.validator.addMethod('passwordregex', function(value, element){
      return this.optional(element) || /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).+$/.test(value);
    },'Password must contains one uppercase, lowercase, number and special character');

    $.validator.addMethod('domainregex', function(value, element) {
        return this.optional(element) || /^(?=.{1,253}$)(?=.{1,63}(?:\.[a-zA-Z]{2,})*$)(?!-)[A-Za-z0-9-]{1,63}(?<!-)(\.[A-Za-z0-9-]{1,63})*\.?[a-zA-Z]{2,}$/.test(value);
    }, 'Please enter a valid domain name.');

    $.validator.addMethod('percentage_validator', function(value, element) {
      return this.optional(element) || /^(?:\d{1,3}(?:\.\d{1,2})?)?$/.test(value);
    }, 'Values upto 2 decimal places are allowed');

  }
}
