import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="two-factor-authentication"
export default class extends Controller {
  connect() {
    console.log('two-factor-authentication')
    // $('#submit-verify-code').on('click', function() { 
    //   $(".modal").hide()
    //   $('.modal-backdrop').remove()
    // });

    // $('#emailVerificationCode').validate();

    // $('#verifyIdentity').validate({
    //   rules: {
    //     "code": {
    //       required: true,
    //       minlength: 6,
    //       maxlength: 6,
    //     }
    //   },
    //   messages: {
    //     "code": {
    //       required: 'Please enter otp.',
    //       minlength: 'OTP must be 6 digits long.',
    //       maxlength: 'OTP must be 6 digits long.',
    //     }
    //   }});

    // document.querySelectorAll('.otp-secrete-field').forEach(field => {
    //   field.addEventListener('input', () => field.value = field.value.replace(/\D/g, '').slice(0, 6));
    // });

  }

  // submit(event) {
  //   this.element.requestSubmit();
  // }
}
