import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="leave-category"
export default class extends Controller {
  connect() {

    customSelect2('#type_category', true)
    var workAffectedCheckbox = $('.work-affected-status-checkbox');
    var workAffectedContent = $('.work-affected-content');

    if (!workAffectedCheckbox.prop('checked')) {
      workAffectedContent.hide();
    }

    // Toggle work affected content when checkbox changes
    workAffectedCheckbox.change(function() {
      if (this.checked) {
        workAffectedContent.slideDown();
      } else {
        workAffectedContent.slideUp();
      }
    });
    $('#year').datepicker({
      format: 'yyyy',
      minViewMode: 'years',
      autoclose: true,
      maxDate: 0
    });
    var working_days = []
    var disabled_days = []
    var days = {
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6
    }

    function restrictInputLength(element, maxLength) {
      $(element).on('keydown', function(event) {
        if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'Tab') {
          return;
        }
        if (!/^\d$/.test(event.key)) {
          event.preventDefault();
        }
        if ($(this).val().length === maxLength) {
          event.preventDefault();
        }
      });
    }
    restrictInputLength('#leave_request_emergency_contact', 10);

    $(".filter-btn").click(function(){
      $(".employee-leave").toggleClass("order-enable");
    });

    $('#leaveRejectForm').validate({
      rules:{
        "leave_request[reject_comment_description]":{
          required: true,
          minlength: 10
        }
      },
      messages:{
        "leave_request[reject_comment_description]":{
          required: 'Please enter description.',
          minlength: 'Minimum 10 character is required.'
        }
      }
    })
    $(document).on('click', '.swal-confirmation', function (e) {
      console.log('bhai bhai')
      e.preventDefault();

      const $btn = $(this);
      const $form = $btn.closest('form');

      if ($form.length && !$form.valid()) return;

      Swal.fire({
        icon: $btn.data('icon') || 'warning',
        html: $btn.data('icon') === 'warning2' 
          ? `<img src="https://img.icons8.com/?size=256&id=1lpBESDEGeuX&format=png" class="swal2-custom-icon"/>` +
            `<div class="swal2-html-container">${$btn.data('confirm-message') || 'Are you sure?'}</div>`
          : $btn.data('confirm-message') || 'Are you sure?',
        showCancelButton: true,
        confirmButtonColor: '#d11a2a',
        confirmButtonText: $btn.data('confirm-text') || 'OK',
        cancelButtonText: $btn.data('cancel-text') || 'Cancel'
      }).then((result) => {
        if (result.isConfirmed) $form.length ? $form.submit() : window.location.href = $btn.attr('href');
      });
    });

    $('#leaveApproveForm').validate({
      rules:{
        'leave_request[paid]':{
          required: true,
          number: true,
          min:0,
          digits: true
        },
        'leave_request[unpaid]':{
          required: true,
          number: true,
          min: 0
        }
      },
      messages:{
        'leave_request[paid]':{
          required: 'Please enter paid count.',
          number: 'Please enter a valid number',
          min: 'Please enter valid value.',
          digits: 'Please enter only numbers'
        },
        'leave_request[unpaid]':{
          required: 'Please enter unpaid count.',
          number: 'Please enter a valid number',
          min: 'Please enter valid value.'
        }
      }
    })

    $('.leaveSettingFrom').validate({
      rules:{
        "leave_setting[full_day]":{
          required: true,
        },
        "leave_setting[half_day]":{
          required: true,
        },
        "leave_setting[early_leave]":{
          required: true,
        },
      },
      messages:{
        "leave_setting[full_day]":{
          required: 'This field is required.',
        },
        "leave_setting[half_day]":{
          required: 'This field is required.',
        },
        "leave_setting[early_leave]":{
          required: 'This field is required.',
        },
      }
    })

    

    $('.leaveFrom').validate({
      rules: {
        "leave_request[type_category]":{
          required: true
        },
        "leave_request[to_date]":{
          required: true,
        },
        "leave_request[from_date]":{
          required: true,
          checkWorkingDays: true
        },
        "leave_request[num_of_days]":{
          required: true
        },
        "leave_request[leave_balance_policy_id]":{
          required: true
        },
        "leave_request[leave_reason]":{
          required: true,
          minlength: 10
        },
        "leave_request[is_planned]":{
          required: true
        },
        "leave_request[work_affected_description]":{
          required: true,
          minlength: 10
        },
        "leave_request[emergency_contact]": {
          digits: true,
          minlength: 10,
          maxlength: 10,
        },
        "leave_request[emergency_email]": {
          emailRegex: true
        }
      },
      messages: {
        "leave_request[type_category]":{
          required: 'This is a required field.'
        },
        "leave_request[from_date]":{
          required: 'This is a required field.'
        },
        "leave_request[to_date]":{
          required: 'This is a required field.'
        },
        "leave_request[leave_balance_policy_id]":{
          required: 'Please select leave type.'
        },
        "leave_request[leave_reason]":{
          required: 'This is a required field.',
          minlength: 'Minimum 10 characters are required.',
        },
        "leave_request[is_planned]":{
          required: 'This is a required field.'
        },
        "leave_request[work_affected_description]":{
          required: 'This is a required field.',
          minlength: 'Minimum 10 characters are required.'
        },
        "leave_request[emergency_contact]": {
          digits: 'Please enter digits only',
          minlength: 'Emergency contact must be 10 character long.',
          maxlength: 'Emergency contact must be 10 character long.',
        },
        "leave_request[emergency_email]": {
          emailRegex: "Enter valid email address"
        }
      },
      errorPlacement: function(error, element) {
        if (element.closest('#leave_emergency_email, #leave_emergency_contact').length) {
          error.insertAfter(element.closest('.input-group'));
        } else {
          error.insertAfter(element);
        }
      },
      highlight: function(element) {
        $(element).addClass('is-invalid');
        $(element).closest('.input-group').addClass('has-error');
      },
      unhighlight: function(element) {
        $(element).removeClass('is-invalid');
        $(element).closest('.input-group').removeClass('has-error');
      }
    })
    $.validator.addMethod("emailRegex", function(value, element) {
      return this.optional(element) || /^[a-zA-Z0-9]+(?:[._%+()-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/i.test(value);
    });

    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^(?!\s)(?=.*[a-zA-Z])[a-zA-Z\s]+$/.test(value);
    },'Contains alphabets only');

    $.validator.addClassRules("task_option_list", {
      required: true,
    });

    $(document).ready(function () {
      function toggleField(checkboxSelector, fieldSelector) {
        if ($(checkboxSelector).is(':checked')) {
          $(fieldSelector).show();
        } else {
          $(fieldSelector).hide();
        }
      }

      toggleField('#leave_request_phone_availability', '#leave_emergency_contact');
      toggleField('#leave_request_email_availability', '#leave_emergency_email');

      $('#leave_request_phone_availability').on('change', function () {
        toggleField(this, '#leave_emergency_contact');
      });

      $('#leave_request_email_availability').on('change', function () {
        toggleField(this, '#leave_emergency_email');
      });
    });

    $.validator.addMethod('numregex', function(value, element){
      return this.optional(element) || /^\d{0,4}(\.\d{0,2})?$/i.test(value);
    },'Contains decimal number only');

    $.validator.addMethod('total_hundred', function(value, element){
      let encash = parseInt($('#leave_category_encashment_percent').val())
      let carryforward = parseInt($('#leave_category_carry_forward_percent').val())
      encash = isNaN(encash) ? 0 : encash;
      carryforward = isNaN(carryforward) ? 0 : carryforward;
      let total = encash + carryforward
      console.log(total);
      return this.optional(element) || (total <= 100);
    },'The sum of both value should not exceed 100');
    
    var updateLapseValue = function() {
      let encashmentPercent = parseInt($('#leave_category_encashment_percent').val()) || 0;
      let carryForwardPercent = parseInt($('#leave_category_carry_forward_percent').val()) || 0;
      console.log(encashmentPercent);
      console.log(carryForwardPercent);
      let total = encashmentPercent + carryForwardPercent;
      let lapsePercent = isNaN(total) ? 0 : 100 - total;

      $('#lapse_value').text('Lapsed : ' + lapsePercent + '%');
    };

    $('#leave_category_name').on('keyup', function(e){
      let temp = $('#leave_category_name').val()
      if((temp.length == 1 || (temp.charAt(temp.length-2) == ' ')) && temp.charAt(temp.length-1) == ' '){
        $('#leave_category_name').val(temp.slice(0,-1))
      }
    })

    $('#leave_category_encashment_applied, #leave_category_carry_forward_applied').change(function() {
      if (!$('#leave_category_carry_forward_applied').is(':checked')) {
        $('#leave_category_carry_forward_percent').val(0)
      }
      if (!$('#leave_category_encashment_applied').is(':checked')) {
        $('#leave_category_encashment_percent').val(0)
      }
      updateLapseValue();
    });

    updateLapseValue();
    $('#leave_category_encashment_percent, #leave_category_carry_forward_percent').keyup(function() {
      updateLapseValue();
    });

    if ( $("#holiday").length != 0 ){ 
      var holidays = JSON.parse($("#holiday").text())
      // holidays.shift()
      holidays.forEach((value) => working_days.push(days[value]));
      var workDays = $("#work_days").length ? JSON.parse($("#work_days").text()) : [];
      var alternateWorkDays = $("#alternate_work_days").length ? JSON.parse($("#alternate_work_days").text()) : [];

      // Combine both work_days and alternate_work_days, filtering out empty values
      working_days = [...new Set([...workDays, ...alternateWorkDays].filter(Boolean).map(day => days[day]))];
      disabled_days = Object.values(days).filter(day => !working_days.includes(day));

    }

    var disabled_dates = [];
    ["#holiday-dates", "#wfh-dates", "#leave-dates"].forEach(function(selector) {
      var element = $(selector);
      if (element.length) {
        disabled_dates = disabled_dates.concat(JSON.parse(element.text()));
      }
    });

    var allowedCount = parseInt($('#leave_balance').text());

    $.validator.addMethod("checkWorkingDays", function(value, element) {
      if ($("#is_earned").text() != 'true') {
        return true;
      }
      var workingDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
      var numOfWorkingDays = 0;
      var fromDate = $('#leave_request_from_date').val();
      fromDate = new Date(fromDate.split('/')[2], (parseInt(fromDate.split('/')[1]) - 1), fromDate.split('/')[0]);
      var toDate = $('#leave_request_to_date').val();
      toDate = new Date(toDate.split('/')[2], (parseInt(toDate.split('/')[1]) - 1), toDate.split('/')[0]);

      for (var currentDate = new Date(fromDate); currentDate <= toDate; currentDate.setDate(currentDate.getDate() + 1)) {
        var dayOfWeek = currentDate.toLocaleDateString('en-US', { weekday: 'long' });
        var formattedDate = currentDate.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' });
        if ($.inArray(dayOfWeek, workingDays) !== -1 && $.inArray(formattedDate, holidays) === -1) {
          numOfWorkingDays++;
        }
      }
      var allowedCount = parseInt($('#leave_balance').text());
      return numOfWorkingDays <= allowedCount;
    }, function() {
      var allowedCount = parseInt($('#leave_balance').text());
      return 'You are not allowed to take more than ' + allowedCount + ' earned leave, please apply for ' + allowedCount + ' days only.';
    });

    $('#leave_request_from_date, #leave_request_to_date, #leave_request_leave_type_id').on('change', function(){
      if ($('#leave_request_from_date').val()!== '' && $('#leave_request_to_date').val()!== '' ) {
        setTimeout(function() {
          $('#leave_request_from_date').valid();
          $('#leave_request_to_date').valid();
        }, 1000);
      }
    });
    $(document).ready(function () {
      const days = { Sunday: 0, Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6 };
      const getJson = (id) => {
        try {
          return JSON.parse($(`#${id}`).text().trim() || "[]");
        } catch (e) {
          console.error(`Error parsing JSON from #${id}:`, e);
          return [];
        }
      };
      const workDays = getJson("work_days").map(day => days[day]);
      const disabledDays = [...Array(7).keys()].filter(d => !workDays.includes(d));
      const alternateDays = getJson("alternate_work_days");
      const alternatePatterns = getJson("alternate_week_patterns").filter(Boolean);

      const alternateInfo = alternateDays.flatMap(day => 
        day ? alternatePatterns.map(pattern => ({ day: days[day], pattern })) : []
      );

      const isDisabledDate = (date) => {
        if (!date.isValid()) return true;
        const day = date.day(), formattedDate = date.format('DD/MM/YYYY');

        const firstDay = moment(date).startOf('month');
        let occurrenceCount = 0;
        for (let i = 1; i <= date.date(); i++) {
          if (moment(firstDay).date(i).day() === day) occurrenceCount++;
        }

        return !alternateInfo.some(info => info.day === day && info.pattern === occurrenceCount) && 
               (disabledDays.includes(day) || disabled_dates.includes(formattedDate));
      };

      const fromDateField = $('#leave_request_from_date');
      const fromDateValue = moment(fromDateField.val(), 'DD/MM/YYYY').isValid() ? moment(fromDateField.val(), 'DD/MM/YYYY') : moment();
      const toDateValue = moment(fromDateField.data('to-date'), 'DD/MM/YYYY').isValid() ? moment(fromDateField.data('to-date'), 'DD/MM/YYYY') : moment().add(1, 'days');

      fromDateField.daterangepicker({
        autoApply: true,
        locale: { format: 'DD/MM/YYYY' },
        startDate: fromDateValue,
        endDate: toDateValue,
        isInvalidDate: isDisabledDate,
        isCustomDate: date => isDisabledDate(date) ? 'disabled-day' : ''
      });
    });

    var startDate;
    console.log("disabled_days  =========>>   " + disabled_days)
    $('.datepicker1').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+365d',
      autoclose: true,
      todayHighlight: true,
      daysOfWeekDisabled: disabled_days,
      datesDisabled: disabled_dates
    }).on('changeDate', function(selected) {
      $('.datepicker2').removeAttr('disabled')
      $('.datepicker2').val('')
      if (!$(this).val()) {
        $('.datepicker2').datepicker('setStartDate', null);      
      } else if (selected.date != undefined) {
        startDate = new Date(selected.date.valueOf());
        $('.datepicker2').datepicker('setStartDate', startDate);
      }
    });

    $('.datepicker2').datepicker({
      format: 'dd/mm/yyyy',
      endDate: '+365d',
      autoclose: true,
      todayHighlight: true,
      daysOfWeekDisabled: disabled_days,
      datesDisabled: disabled_dates
    })
    // $('.datepicker2').datepicker('setStartDate', $('.datepicker1').datepicker('getDate'));

    if ($('.datepicker1').datepicker('getDate') == null) {
      $('.datepicker2').attr('disabled', 'disabled')
    }

    // jQuery(document).ready(function() {
    //   const employee_id =  $('#employee_id').text();
    //   const category =  $('#leave_request_leave_type_id').text();
    //     $.ajax({
    //       type: "get",
    //       url: category + "/leave_balance_count." + employee_id
    //     });
    // });

    jQuery(document).ready(function() {
      if ( $("#holiday").length != 0 ) {
        $.ajax({
          type: "get",
          url: "leave_balance/0"
        });
      }
    });

    $('#leave_request_leave_balance_policy_id').on('change', function(){
      var allowedCount = parseInt($('#leave_balance').text());
      if ($(this).val() != '') {
        $.ajax({
          type: "get",
          url: "leave_balance/"+$(this).val()
        });
      } else {
        $.ajax({
          type: "get",
          url: "leave_balance/0"
        });
      }
    });
    
    
    $('#year').on('change', function(){
      let employee_id =  $('#employee_id').text();
      let year = $('#year').val();
      
      if(employee_id.length) {
        $.ajax({
          type: 'get',
          url: '/organizers/leave_requests/'+ employee_id +'/update_leave_balance_sheet.'+year
        });  
      } else {
        $.ajax({
          type: 'get',
          url: '/employee/leaves/update_leave_balance_sheet.'+year
        });
      }
    });

    $('#year').on('change', function(){
      let employee_id =  $('#employee_id').text();
      let year = $('#year').val();
      
      if(employee_id.length) {
        $.ajax({
          type: 'get',
          url: '/organizers/leave_requests/'+ employee_id +'/update_leave_balance_sheet.'+year
        });  
      } else {
        $.ajax({
          type: 'get',
          url: '/employee/leaves/update_leave_balance_sheet.'+year
        });
      }
    });

    if ($('#leave_category_allowed').is(':checked')) {
      $('#allowed_count_section').addClass('d-block')
      $('#allowed_count_section').removeClass('d-none')
    }
    $('#leave_category_earned').change(function() {
      if ($(this).is(':checked')) {
        $('#leave_category_allowed').prop('checked', false);
        $('#allowed_count_section').addClass('d-none')
        $('#allowed_count_section').removeClass('d-block')
      }
    });

    $('#leave_category_allowed').change(function() {
      if ($(this).is(':checked')) {
        $('#allowed_count_section').addClass('d-block')
        $('#allowed_count_section').removeClass('d-none')
        $('#leave_category_earned').prop('checked', false)
      } else {
        $('#allowed_count_section').addClass('d-none')
        $('#allowed_count_section').removeClass('d-block')
      }
    });

    if ($('#leave_category_encashment_applied').is(':checked')) {
      $('#leave_encashment_field').addClass('d-block')
      $('#leave_encashment_field').removeClass('d-none')
    }
    if ($('#leave_category_carry_forward_applied').is(':checked')) {
      $('#leave_carry_forward_field').addClass('d-block')
      $('#leave_carry_forward_field').removeClass('d-none')
    }

    $('#leave_category_encashment_applied').change(function() {
      if ($(this).is(':checked')) {
        $('#leave_encashment_field').addClass('d-block')
        $('#leave_encashment_field').removeClass('d-none')
      } else {
        $('#leave_encashment_field').addClass('d-none')
        $('#leave_encashment_field').removeClass('d-block')
      }
    });

    $('#leave_category_carry_forward_applied').change(function() {
      if ($(this).is(':checked')) {
        $('#leave_carry_forward_field').addClass('d-block')
        $('#leave_carry_forward_field').removeClass('d-none')
      } else {
        $('#leave_carry_forward_field').addClass('d-none')
        $('#leave_carry_forward_field').removeClass('d-block')
      }
    });

    // $(document).on('change', '.task_option_list', function() {
    //   $('.task_option_list option').prop('disabled', false);
    //   var selectedValues = $('.task_option_list').map(function() { return $(this).val(); }).get();
    //   var selectedValues = selectedValues.filter(function(value) {
    //     return value !== "";
    //   });

    //   selectedValues.forEach(function(element) {
    //     $('.task_option_list').each(function(){
    //       if($(this).val() !== element ){
    //         $(this).find('option[value="' + element + '"]').prop('disabled', true);
    //       }
    //     })
    //   });

    // });

    function updateTaskOptionList() {
      $('.task_option_list option').prop('disabled', false);
      var selectedValues = $('.task_option_list').map(function() {
        return $(this).val();
      }).get();

      selectedValues = selectedValues.filter(function(value) {
        return value !== "";
      });

      selectedValues.forEach(function(element) {
        $('.task_option_list').each(function() {
          if ($(this).val() !== element) {
            $(this).find('option[value="' + element + '"]').prop('disabled', true);
          }
        });
      });
    }

    // Trigger on change event
    $(document).on('change', '.task_option_list', function() {
      updateTaskOptionList();
    });

    // Trigger on button click event
    $(document).on('click', '.remove-task-btn', function() {
      console.log('remove btn clicked')
      updateTaskOptionList();
    });

    $(document).on('cocoon:after-insert', function(event, insertedItem) {
      var selectedValues = $('.task_option_list').map(function(){ return $(this).val(); }).get();
      var selectedValues = selectedValues.filter(function(value) { return value !== ""; });
      selectedValues.forEach(function(element) {
        $(insertedItem).find('.task_option_list').find('option[value="' + element + '"]').prop('disabled', true);
      });
    });

    // $('#leave_request_from_date').datepicker({
    //   format: 'dd/mm/yyyy',
    //   autoclose: true,
    //   todayHighlight: true
    // }).on('changeDate input', function(selected) {
    //   if (!$(this).val()) {
    //     $('#leave_request_to_date').datepicker('setStartDate', null);      
    //   } else if (selected.date != undefined) {
    //     var minDate = new Date(selected.date.valueOf());
    //     $('#leave_request_to_date').datepicker('setStartDate', minDate);
    //   }
    // });

    $('#leave_request_to_date').datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      todayHighlight: true
    }).on('changeDate input', function(selected) {
      if (!$(this).val()) {
        $('#leave_request_from_date').datepicker('setEndDate', null);      
      } else if (selected.date != undefined) {
        var maxDate = new Date(selected.date.valueOf());
        $('#leave_request_from_date').datepicker('setEndDate', maxDate);
      }
    });
    $('#from_date').datepicker('setEndDate', $('#to_date').datepicker('getDate'));
    $('#to_date').datepicker('setStartDate', $('#from_date').datepicker('getDate'));
    loadSelectForLeaveTask()
  }

  addLeaveTask(){
    $(".add-leave-task a").click()
    if($('.nested-fields').length > 1){
      $('.no_leave_task').hide()
    }else{
      $('.no_leave_task').show()
    }
    loadSelectForLeaveTask()
  }
  updateLapseValue() {
    let encashmentPercent = parseInt($('#leave_category_encashment_percent').val()) || 0;
    let carryForwardPercent = parseInt($('#leave_category_carry_forward_percent').val()) || 0;

    let total = encashmentPercent + carryForwardPercent;
    let lapsePercent = isNaN(total) ? 0 : 100 - total;

    $('#lapse_value').text('Lapse %(days) : ' + lapsePercent + '%');
  }
}
window.loadSelectForLeaveTask = function() {
  $('.task_option_list, .leave_task_priority, .leave_task_employee').each(function(index, item) {
    if($(item).data('selecttwo') == true){
      $(item).select2()
    }
  })
}


