import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="performance-ratings"
export default class extends Controller {
  connect() {
    console.log('hello ok')

    $(".filter-btn").click(function(){
      $(".organizer-team-rating").toggleClass("order-enable");
    });
    
    $(".filter-btn").click(function(){
      $(".employee-my-rating").toggleClass("order-enable");
    });

    $(".filter-btn").click(function(){
      $(".employee-team-rating").toggleClass("order-enable");
    });

    $(".RatingForm").validate()
    $(".rating-form").validate({
      rules: {
        "performance_rating[rating]": {
          required: true,
          min:0,
          max: 100,
          number: true
        },
        "performance_rating[comment]": {
          required: true,
          minlength: 10
        }
      },
      messages: {
        "performance_rating[rating]": {
          required: 'Please enter rating.',
          min:'Rating should be between 0-100.',
          max: 'Rating should be between 0-100.'
        },
        "performance_rating[comment]": {
          required: "Please enter comment.",
          minlength: 'Please enter atleast 10 character.'
        }
      }
    });

    // $("#performance_rating_rating").on('input', function() {
    //   var val = $(this).val();
    //   if(val.indexOf('.') !== -1) {
    //     var splitVal = val.split('.');
    //     if(splitVal[1].length > 2) {
    //       splitVal[1] = splitVal[1].substring(0, 2);
    //       val = splitVal.join('.');
    //       $(this).val(val);
    //     }
    //   }
    // });
  }
}
